export function getVideoUrl(videoId) {
  const baseUrlPrefix = 'https://www.facebook.com/'
  return baseUrlPrefix + videoId
}

export function getVideoIdFromUrl(url) {
  const urlRegex = /facebook\.com\/(.+\/videos\/[A-Za-z0-9._%-]*)/gi
  const match = urlRegex.exec(url)
  if (match && match.length && match[1]) {
    //if there is a match, the second group is the id we want
    return match[1]
  }
}
