export {RemoteGlobalsInterface} from './RemoteGlobalsInterface'
export {RemoteModelInterface} from './RemoteModelInterface'
import * as backgroundUtils from './backgroundUtils'
import * as componentsHooks from './RMIHooks/componentsHooks'
import * as linkUtils from './linkUtils'
import * as richTextUtils from './richTextUtils'
import * as typeData from './typeUtils/typeData'
import * as uriUtils from './uriUtils'
import * as videoUrlsUtils from './videoUrlsUtils/videoUrlsUtils'
import * as widgetUtils from './widgetUtils'
import * as mediaItemUtils from './mediaItemUtils/mediaItemUtils'
import {TypeUtils} from './typeUtils/typeUtils'
import repeaterUtils from './repeaterUtils/repeaterUtils'
import * as platformizedEndpointsUtils from './platformizedEndpointsUtils'
import * as mediaSrcHandler from './mediaSrcHandler/mediaSrcHandler'

export const typeUtils = new TypeUtils(typeData)
export {
  linkUtils,
  widgetUtils,
  mediaItemUtils,
  mediaSrcHandler,
  uriUtils,
  richTextUtils,
  videoUrlsUtils,
  repeaterUtils,
  backgroundUtils,
  componentsHooks,
  platformizedEndpointsUtils
}
