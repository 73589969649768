import {
  SPLITTED_DATA_TYPES,
  getAddedItems,
  getItemId,
  getOriginalId,
  getRemovedItems,
  getTemplateCompIds as getTemplateCompsId,
  getUniqueDisplayedId
} from './repeaterItemsUtils'

export default {
  items: {
    getAddedItems,
    getRemovedItems,
    getTemplateCompsId
  },
  structure: {
    getUniqueDisplayedId,
    getOriginalId,
    getItemId,
    SPLITTED_DATA_TYPES
  }
}

