import _ from 'lodash'
import * as mediaItemUtils from './../mediaItemUtils/mediaItemUtils'

const errors = mediaItemUtils.errors
const types = mediaItemUtils.types

const matchers = {
  externalUrl: /(^https?)|(^data)|(^blob)|(^\/\/)/,
  inlineSvg: /<svg[\s\S]*>[\s\S]*<\/svg>/im
}

const extraMatchersByType = {
  [mediaItemUtils.types.VECTOR]: [matchers.externalUrl, matchers.inlineSvg],
  [mediaItemUtils.types.IMAGE]: [matchers.externalUrl],
  [mediaItemUtils.types.DOCUMENT]: [],
  [mediaItemUtils.types.VIDEO]: []
}

/**
 * @public
 * Create a MediaItem in the form of 'wix:<media_type>:<uri>/...' or extra supported source(e.g., external url) of one of the supported type
 * @param {string} mediaId required for all types
 * @param {MediaType} type required, one of the supported types
 * @param {string} [title] optional for all types
 * @param {number} [width] required for video and image
 * @param {number} [height] required for video and image
 * @param {string} [posterId] required for video
 * @param {string} [watermark] optional for image
 * @returns {{item: string}|{error: string}}
 */
function createMediaSrc({mediaId, type, title, width, height, posterId, watermark}) {
  const extraMatchers = extraMatchersByType[type]
  if (_.some(extraMatchers, matcher => matcher.test(mediaId))) {
    return {item: mediaId}
  }

  return mediaItemUtils.createMediaItemUri({mediaId, type, title, width, height, posterId, watermark})
}

/**
 * @public
 * Parse a media item url of one of the supported types, including extra source type(e.g. external url)
 * @param {string} mediaItemSrc
 * @param {string} type
 * @returns {{}|{error: string}}
 */
function parseMediaSrc(mediaItemSrc, type) {
  if (!_.includes(types, type)) {
    return {error: errors.unknown_media_type}
  }

  const extraMatchers = extraMatchersByType[type]
  if (_.some(extraMatchers, matcher => matcher.test(mediaItemSrc))) {
    return {type, mediaId: mediaItemSrc}
  }

  const mediaItemUri = mediaItemUtils.parseMediaItemUri(mediaItemSrc)

  if (mediaItemUri.error === errors.non_string_media_id) {
    return mediaItemUri
  }

  if (mediaItemUri.error === errors.unknown_media_type || type !== mediaItemUri.type) {
    return {error: errors.bad_media_id}
  }

  return mediaItemUri
}

/**
 * @public
 * Checks if a given url is a valid media source url
 * @param {string} mediaSrc
 * @param {string} type
 * @returns {boolean}
 */
function isValidMediaSrc(mediaSrc, type) {
  const isValidMediaItemUri = mediaItemUtils.isValidMediaItemUri(mediaSrc, type)
  if (isValidMediaItemUri) {
    return true
  }
  const matchers = extraMatchersByType[type]
  return _.some(matchers, matcher => matcher.test(mediaSrc))
}

export {
  isValidMediaSrc,
  createMediaSrc,
  parseMediaSrc,
  errors,
  types
}