export function getVideoUrl(videoId) {
  const baseUrlPrefix = 'https://vimeo.com/'
  return baseUrlPrefix + videoId
}

export function getVideoIdFromUrl(url) {
  // TODO: add support for additional vimeo url formats
  const vimeoUrlRegex = /vimeo\.com\/(\d+)$/gi
  const match = vimeoUrlRegex.exec(url)
  if (match && match.length && match[1]) {
    //if there is a match, the second group is the id we want
    return match[1]
  }
}
