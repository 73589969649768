export function getVideoUrl(videoId) {
  const baseUrl = 'https://dailymotion.com/video/'
  return baseUrl + videoId
}

export function getVideoIdFromUrl(url) {

  const urlRegex = /dailymotion\.com\/(?:video|playlist)\/([^_/]+)/gi
  const match = urlRegex.exec(url)
  if (match && match.length && match[1]) {
    //if there is a match, the second group is the id we want
    return match[1]
  }
}
