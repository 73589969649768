export function getVideoUrl(videoId) {
  const baseUrlPrefix = 'https://www.youtube.com/watch?v='
  return baseUrlPrefix + videoId
}

export function getVideoIdFromUrl(url) {
  // TODO: add support for additional youtube url formats
  // Test for long youtube url: http://youtube.com/watch?[...&]v=[VIDEO_ID]
  const YTLongUrl = /(?:youtube\.com\/watch[^\s]*[\?&]v=)([\w-]+)/g
  // Test for short youtube url: http://youtu.be/[VIDEO_ID]
  const YTShortUrl = /(?:youtu\.be\/)([\w-]+)/g

  const match = YTLongUrl.exec(url) || YTShortUrl.exec(url)
  if (match && match.length && match[1]) {
    //if there is a match, the second group is the id we want
    return match[1]
  }
}
