import _ from 'lodash'

const DEFAULT_STYLE_PARAMS = {
  backgroundColor: 'bg',
  borderColor: 'brd',
  borderWidth: 'brw',
  borderRadius: 'rd',
  color: 'txt'
}

const STYLE_PARAMS_OVERRIDES = {
  'wysiwyg.viewer.components.inputs.FileUploader': {
    backgroundColor: 'btn_bg',
    borderColor: 'btn_brd',
    borderWidth: 'btn_brw',
    borderRadius: 'btn_rd',
    color: 'btn_txt'
  }
}

const SKIN_TYPE_EXPORTS = {
  'skins.input.RadioGroupDefaultSkin': 'wysiwyg.common.components.radio.viewer.skins.RadioButtonDefaultSkin'
}

export function getSkinName(skinType) {
  return _.get(SKIN_TYPE_EXPORTS, skinType) || skinType
}

export function convertStyleParamForViewerType(viewerType, styleParam) {
  const overrides = _.get(STYLE_PARAMS_OVERRIDES, viewerType)
  const styleParamMap = overrides ? overrides : DEFAULT_STYLE_PARAMS
  return _.get(styleParamMap, styleParam, styleParam)
}
